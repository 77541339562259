<i18n>
{
  "en": {
    "title-create": "New Task Type",
    "title-update": "Update Task Type",
    "input-1": "Name",
    "input-2": "Color",
    "input-3": "Status",
    "create": "Create task",
    "update": "Save updates",
    "delete": "Delete task type"
  },
  "pt-BR": {
    "title-create": "Novo Tipo de Tarefa",
    "title-update": "Editar Tipo de Tarefa",
    "input-1": "Nome",
    "input-2": "Cor",
    "input-3": "Status",
    "create": "Criar tarefa",
    "update": "Salvar alterações",
    "delete": "Deletar tipo de tarefa"
  }
}
</i18n>

<template>
  <modal-action @close="closeTask" :columns="1">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon :icon="taskExist ? 'edit' : 'plus'" />
            </div>
            {{ taskExist ? $t("title-update") : $t("title-create") }}
          </div>
          <div class="options">
            <we-icon-button
              class="big-mobile"
              v-if="taskExist"
              @click="deleteTask"
              :icon="['far', 'trash-alt']"
              :name="$t('delete')"
              cy="delete-task-type"
            />
            <we-icon-button
              class="big-mobile"
              icon="times"
              @click="closeTask"
            />
          </div>
        </div>
        <div class="modal-body">
          <div class="fields">
            <we-input
              type="text"
              :label="$t('input-1')"
              v-model="task.name"
              cy="create-task-type-name"
            />
            <we-input
              type="color"
              :label="$t('input-2')"
              v-model="task.color"
              :items="colorList"
              cy="create-task-type-color"
            />
            <we-input
              type="tags"
              :label="$t('input-3')"
              v-model="task.status_options"
              :items="task.status_options"
              :clearable="false"
              cy="create-task-type-status"
            />
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="taskExist ? $t('update') : $t('create')"
            :icon="taskExist ? 'check' : 'plus'"
            :loading="btnLoading"
            @click="taskAction"
            cy="create-task-type"
          />
        </div>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      loading: true,
      task: {
        name: "",
        color: "#0C80F2",
        status_options: []
      }
    };
  },
  computed: {
    taskExist() {
      return this.$route.params.id;
    },
    colorList() {
      return this.$store.getters.getProductColors;
    }
  },
  methods: {
    ...mapActions([
      "editTaskRequest",
      "createTaskRequest",
      "deleteTaskRequest",
      "taskRequest"
    ]),
    closeTask() {
      this.$router.push({
        name: "tasksConfigs"
      });
      this.btnLoading = false;
    },
    taskAction() {
      this.taskExist ? this.editTask() : this.createTask();
    },
    async deleteTask() {
      this.btnLoading = true;
      await this.deleteTaskRequest(this.task.id);
      this.closeTask();
    },
    async editTask() {
      this.btnLoading = true;
      await this.editTaskRequest(this.task);
      this.closeTask();
    },
    async createTask() {
      this.btnLoading = true;
      await this.createTaskRequest(this.task);
      this.closeTask();
    },
    async doRequests() {
      if (this.taskExist) {
        await this.taskRequest(this.$route.params.id);

        this.task = this.$store.getters.getTask;
      }

      this.loading = false;
    }
  },
  mounted() {
    this.doRequests();
  }
};
</script>
