<i18n>
{
  "en": {
    "title": "Task Types",
    "create": "New task type",
    "create-mobile": "New",
    "list": "Task type list",
    "not-found": "Task type not found",
    "not-found-description": "Check your search or filter again or click on button below to create a new one"
  },
  "pt-BR": {
    "title": "Tipos de Tarefas",
    "create": "Novo tipo de tarefa",
    "create-mobile": "Novo",
    "list": "Lista de tipos de tarefas",
    "not-found": "Nenhum tipo de tarefa encontrada",
    "not-found-description": "Verifique sua pesquisa novamente ou clique no botão abaixo para criar"
  }
}
</i18n>

<template>
  <div id="tasks-configs">
    <nav-top
      :returnPage="!isDesktop ? 'records' : 'configurations'"
      :title="$t('title')"
    >
      <template #top-content>
        <we-button
          class="success mobile-icon header-action"
          :text="$t('create')"
          :mobile-text="$t('create-mobile')"
          icon="plus"
          @click="$router.push({ name: 'createTask' })"
          cy="create-task-type-cta"
        />
      </template>
    </nav-top>
    <div class="board">
      <router-view />
      <div class="tasks-grid">
        <div class="content">
          <div class="we-title">
            {{ $t("list") }}
          </div>
          <div class="tasks-list">
            <we-not-found v-if="tasks.length === 0">
              <template #title> {{ $t("not-found") }} </template>
              <template #description>
                {{ $t("not-found-description") }}
              </template>
              <template #actions>
                <we-button
                  class="success"
                  :text="$t('create')"
                  icon="plus"
                  @click="$router.push({ name: 'createTask' })"
                />
              </template>
            </we-not-found>
            <div
              class="item"
              v-for="task in tasks"
              :key="task.id"
              @click="
                $router.push({
                  name: 'editTask',
                  params: { id: task.id }
                })
              "
            >
              <div class="name">
                <div class="color" :class="task.color"></div>
                <we-text-tooltip :text="task.name" lines="1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <we-loading-overflow :loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true,
      entity: "contact"
    };
  },
  computed: {
    tasks() {
      return this.$store.getters.getTasks;
    }
  },
  methods: {
    ...mapActions(["tasksRequest"]),
    async doRequests() {
      await this.tasksRequest();
      this.loading = false;
    }
  },
  mounted() {
    this.doRequests();
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name.includes("create") || from.name.includes("edit")) {
      this.loading = true;
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#tasks-configs {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 69px calc(100vh - 69px);
  position: relative;

  .board {
    background: var(--background-2);
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .tasks-grid {
    max-width: 767px;
    height: fit-content;
    margin: 0 auto;
    padding: 69px 50px;

    @include mobile {
      padding: 20px 15px;
      padding-bottom: 200px;
    }

    .content {
      .we-title {
        text-align: center;
        font-size: 16px;
        font-weight: $semibold;
        color: var(--text-2);
        margin-bottom: 18px;

        @include mobile {
          display: none;
        }
      }

      .tasks-list {
        min-height: 320px;
        position: relative;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 11px 14px;
          margin-bottom: 12px;
          border-radius: 10px;
          background: var(--background-1);
          border: 1px solid var(--line);
          cursor: pointer;

          @include mobile {
            border-radius: 7px;
            margin-bottom: 8px;
          }

          &:hover {
            border-color: var(--primary);
          }

          .name {
            font-size: 16px;
            font-weight: $medium;
            color: var(--text-1);
            display: flex;
            align-items: center;
            flex: 1;
            min-width: 0;

            @include mobile {
              font-size: 14px;
              font-weight: $regular;
            }

            .color {
              height: 25px;
              width: 4px;
              border-radius: 4px;
              margin-right: 12px;
              background: var(--blue);
              min-width: 4px;
            }

            span {
              margin-left: 6px;
              font-size: 14px;
              color: var(--green);
            }

            .we-text-tooltip {
              min-width: 0;
            }
          }
        }
      }
    }
  }
}
</style>
